<template>
  <div class="mb-20 st-data-entry">
    <hot-table
      ref="hot"
      class="table-body"
      :data="data"
      stretch-h="all"
      :columns="columns"
      :nested-headers="nestedHeaders"
      license-key="non-commercial-and-evaluation"
      :settings="hotSettings"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import deepClone from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import { HotTable } from '@handsontable/vue';
import Handsontable from 'handsontable';
import { enumeratorColumns, enumeratorColumnsDataFields } from '../../../../util/enumerators';

const buildColumns = length => new Array(length).fill({
  type: 'numeric'
});

export default {
  name: 'StandardizationTestDataEntry',
  components: {
    HotTable
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    enumerators: {
      type: Array,
      required: true
    },
    onDataEntryChanges: {
      type: Function,
      required: true
    },
    onEnumeratorCount: {
      type: Function,
      required: true
    }
  },
  data() {
    const columnCount = this.enumerators.length * enumeratorColumns().length;
    return {
      columnCount,
      cache: undefined,
      mounted: false,
      data: Handsontable.helper.createEmptySpreadsheetData(100, columnCount),
      columns: buildColumns(columnCount),
      nestedHeaders: [],
      hotSettings: {
        height: 550,
        width: '100%',
        columnSorting: false,
        sortIndicator: false,
        rowHeaders: true,
        fillHandle: false,
        colWidths: 85,
        maxRows: 100,
        minRows: 100,
        afterGetColHeader: (col, header) => {
          if (header && header.classList) {
            header.classList.add('htLeft');
          }
        },
        afterPaste() {
          this.deselectCell();
        },
        afterChange: (changes, source) => {
          this.updateCell(changes);
        },
        customBorders: []
      }
    };
  },
  computed: {
    ...mapState({
      project: state => state.survey.project,
      standardization: state => state.survey.standardization,
      surveyId: state => state.survey.surveyId,
      lastUpdated: state => state.standardizationGroups.lastUpdated,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    columnMetadata() {
      const sortedEnumerators = this.createSortedEnumerators();
      return sortedEnumerators
        .map(enumerator => enumeratorColumnsDataFields
          .map(metric => (
            {
              enumeratorId: enumerator.id,
              metric
            }
          )))
        .flat();
    }
  },
  watch: {
    enumerators() {
      this.loadStandardization();
    },
    '$i18n.locale': function() {
      this.nestedHeaders = this.initialNestedHeaders();
    },
    standardization: {
      deep: true,
      immediate: true,
      handler(value, old) {
        this.setStandardizationData(value);
        if (!this.mounted && this.cache && this.cache.length > 0) {
          this.mounted = true;
          const key = 'standardization_loading';
          this.$message.loading({ content: this.$t('components.notifications.settingUpDataEntryTable'), key });
          this.loadCacheIntoTableData(key);
        }
      }
    }
  },
  created() {
    this.nestedHeaders = this.initialNestedHeaders();
    if (this.standardization?.length === 0) {
      this.loadStandardization();
    }
  },
  mounted() {
    if (this.isSupervisor && this.$refs?.hot?.hotInstance) {
      this.$refs.hot.hotInstance.updateSettings({
        readOnly: true,
        contextMenu: false,
        disableVisualSelection: true,
        manualColumnResize: false,
        manualRowResize: false,
        comments: false
      });
    }
  },
  methods: {
    ...mapActions(['getStandardization', 'updateStandardization']),
    translateMetric(metric) {
      const metrics = {
        Height: this.$t('values.height'),
        Weight: this.$t('values.weight'),
        MUAC: this.$t('components.description.MUAC')
      };
      return metrics[metric];
    },
    setStandardizationData(value) {
      const enumeratorCount = {};
      const filteredData = value.filter(item => item.groupId === this.groupId);
      const grouped = groupBy(filteredData, 'childId');
      const groupedKeys = Object.keys(grouped);
      const sortedEnumerators = this.createSortedEnumerators();

      if (groupedKeys.length) {
        this.cache = this.data;
        groupedKeys.forEach((key) => {
          const childResults = grouped[key];
          const result = sortedEnumerators.map(enumerator => {
            const childResult = childResults.find(item => item.id === enumerator.id);

            if (childResult) {
              const { weight1, weight2, height1, height2, muac1, muac2 } = childResult;

              const addEnumCount = weight1 !== null && weight2 !== null ? 1 : 0;
              if (enumerator.id in enumeratorCount) {
                enumeratorCount[enumerator.id] += addEnumCount;
              } else {
                enumeratorCount[enumerator.id] = addEnumCount;
              }

              return [weight1, weight2, height1, height2, muac1, muac2];
            }
            return [null, null, null, null, null, null];
          });
          this.cache[key - 1] = result.flat();
        });
      } else {
        const columnCount = this.enumerators.length * enumeratorColumns().length;
        this.cache = Handsontable.helper.createEmptySpreadsheetData(100, columnCount);
      }
      this.onEnumeratorCount(enumeratorCount);
    },
    initialNestedHeaders() {
      const sortedEnumerators = this.createSortedEnumerators();
      const enumeratorNameHeaders = sortedEnumerators
        .map(enumerator => ({ label: `${enumerator.firstName} ${enumerator.familyName} ${enumerator.supervisor ? `(${this.$t('values.supervisor')})` : ''}`, colspan: 6 }));
      const enumeratorColumnNames = enumeratorColumns(this).map(colObj => Object.values(colObj)).flat();
      const enumeratorDataHeaders = sortedEnumerators
        .map(() => (enumeratorColumnNames))
        .flat();
      return [
        enumeratorNameHeaders,
        enumeratorDataHeaders
      ];
    },
    createSortedEnumerators() {
      return sortBy(this.enumerators, enumerator => !enumerator.supervisor);
    },
    updateCell(changes) {
      if (changes) {
        const updates = changes
          .filter(([, column]) => (column < this.columns.length))
          .map(([row, column, oldValue, newValue]) => {
            const columnMetadata = this.columnMetadata[column];
            return {
              enumeratorId: columnMetadata.enumeratorId,
              [columnMetadata.metric]: newValue,
              childId: row + 1
            };
          });

        this.onDataEntryChanges();
        this.saveChanges(updates);
      }
    },
    async saveChanges(updates) {
      try {
        const response = await this.updateStandardization({
          projectId: this.$store.state.survey.project.id,
          surveyId: this.surveyId,
          updates
        });
        this.$message.success('Data saved!');
        return response;
      } catch (err) {
        this.$alert().danger('An error occured saving standardization results');
        return err;
      }
    },
    loadStandardization() {
      const columnCount = this.enumerators.length * enumeratorColumns().length;
      if (columnCount < this.columnCount) {
        this.columns = this.columns.slice(0, columnCount);
      } else if (columnCount > this.columnCount) {
        const columns = [...this.columns, ...buildColumns(columnCount - this.columnCount)];
        this.columns = columns;
      }
      this.columnCount = columnCount;
      this.nestedHeaders = this.initialNestedHeaders();
      this.getStandardization({
        projectId: this.$store.state.survey.project.id,
        surveyId: this.surveyId
      });
      this.mounted = false;
    },
    loadCacheIntoTableData: debounce(function(key) {
      const data = deepClone(this.cache);
      this.$refs.hot.hotInstance.loadData(data);
      this.$message.success({ content: this.$t('components.notifications.tableReady'), key });
    }, 500)
  }
};
</script>

<style lang="scss">
  .st-data-entry .htCore {
    tr:nth-child(1) > th.htLeft:nth-child(2n+0),
    tr:nth-child(2) > th:nth-child(6n+1),
    td:nth-child(6n+1) {
      border-right: 2px solid #959595;
    }
  }
</style>
